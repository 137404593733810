/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

.fullwidth{
    width: 100%;
}

.marginT10{
    margin-top: 10px;
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: solid 1px #CCC;
    border-radius: 5px;
}

.pseudoLink{
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;

}

.pseudoLink:hover{
    color: #0056b3;
    text-decoration: underline;
}


.firstContainer{
    margin-top: 10px;
}

.actionTop{
    margin-bottom: 10px;
}

table.table tr:hover{
    background-color: #F1F1F1;
}

.headerName{
    color: rgba(255, 255, 255, 0.5);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    display: block;
}

.navbar-dark .navbar-nav  .active-link{
    color: #FFFFFF;
}

.identif{
    line-height: 40px;
}

.page-link.active{
    font-weight: bold;
}

.page-link{
    cursor: pointer;

}

.noData{
    margin: 1em 0 2em 0;
    padding: 1em;
    text-align: center;
    border: solid 2px #CCCCCC
}


@media (min-width: 1200px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 96%;
        margin-left: 2%;
    }
}